/* eslint-disable camelcase */
import Select from 'react-select';
import { Icon } from 'react-icons-kit';
import { ic_short_text } from 'react-icons-kit/md/ic_short_text';
import { ic_subject } from 'react-icons-kit/md/ic_subject';
// import { ic_looks_one } from 'react-icons-kit/md/ic_looks_one';
import { ic_sentiment_very_satisfied } from 'react-icons-kit/md/ic_sentiment_very_satisfied';
import { star } from 'react-icons-kit/fa/star';
import { ic_radio_button_checked } from 'react-icons-kit/md/ic_radio_button_checked';
import { ic_check_box } from 'react-icons-kit/md/ic_check_box';
import { ic_event_available } from 'react-icons-kit/md/ic_event_available';
import { ic_arrow_drop_down_circle } from 'react-icons-kit/md/ic_arrow_drop_down_circle';
import { thLarge } from 'react-icons-kit/fa/thLarge';
import { arrowsH } from 'react-icons-kit/fa/arrowsH';
import { ic_linear_scale } from 'react-icons-kit/md/ic_linear_scale';
// import { ic_view_list } from 'react-icons-kit/md/ic_view_list';
import { thList } from 'react-icons-kit/fa/thList';

const QuestionTypeSelector = props => {
  const { name, type, onChange } = props;

  const QUESTION_TYPES = [
    {
      value: 'input',
      label: (
        <div className="type-single-option">
          <span className="icon-wrapper input">
            <Icon icon={ic_short_text} />
          </span>
          Short text
        </div>
      )
    },
    {
      value: 'textarea',
      label: (
        <div className="type-single-option">
          <span className="icon-wrapper textarea">
            <Icon icon={ic_subject} />
          </span>
          Long text
        </div>
      )
    },
    // {
    //   value: 'textarea',
    //   label: (
    //     <div className="type-single-option">
    //       <span className="icon-wrapper textarea">
    //         <Icon icon={ic_looks_one} />
    //       </span>
    //       Number
    //     </div>
    //   )
    // },
    {
      value: 'radio',
      label: (
        <div className="type-single-option">
          <span className="icon-wrapper radio">
            <Icon icon={ic_radio_button_checked} />
          </span>
          Multiple Choice
        </div>
      )
    },
    {
      value: 'checkbox',
      label: (
        <div className="type-single-option">
          <span className="icon-wrapper checkbox">
            <Icon icon={ic_check_box} />
          </span>
          Checkbox
        </div>
      )
    },
    {
      value: 'dropdown',
      label: (
        <div className="type-single-option">
          <span className="icon-wrapper dropdown">
            <Icon icon={ic_arrow_drop_down_circle} />
          </span>
          Dropdown
        </div>
      )
    },
    {
      value: 'buttons',
      label: (
        <div className="type-single-option">
          <span className="icon-wrapper buttons">
            <Icon icon={thLarge} />
          </span>
          Buttons
        </div>
      )
    },
    {
      value: 'sentiment',
      label: (
        <div className="type-single-option">
          <span className="icon-wrapper sentiment">
            <Icon icon={ic_sentiment_very_satisfied} />
          </span>
          Sentiment
        </div>
      )
    },
    {
      value: 'rating',
      label: (
        <div className="type-single-option">
          <span className="icon-wrapper rating">
            <Icon icon={star} />
          </span>
          Rating
        </div>
      )
    },
    {
      value: 'mdtext',
      label: (
        <div className="type-single-option">
          <span className="icon-wrapper dropdown">
            <Icon icon={ic_subject} />
          </span>
          Plain text
        </div>
      )
    },
    // {
    //   value: 'hidden',
    //   label: (
    //     <div className="type-single-option">
    //       <span className="icon-wrapper dropdown">
    //         <Icon icon={ic_subject} />
    //       </span>
    //       Hidden
    //     </div>
    //   )
    // },
    {
      value: 'date',
      isDisabled: true,
      label: (
        <div className="type-single-option">
          <span className="icon-wrapper date">
            <Icon icon={ic_event_available} />
          </span>
          Date (Soon!)
        </div>
      )
    },
    {
      value: 'slider',
      isDisabled: true,
      label: (
        <div className="type-single-option">
          <span className="icon-wrapper slider">
            <Icon icon={arrowsH} />
          </span>
          Slider (Soon!)
        </div>
      )
    },
    {
      value: 'matrix',
      isDisabled: true,
      label: (
        <div className="type-single-option">
          <span className="icon-wrapper matrix">
            <Icon icon={thList} />
          </span>
          Matrix (Soon!)
        </div>
      )
    }
  ];

  const selected = QUESTION_TYPES.find(opt => opt.value === type);

  return (
    <div className="question-type-wrapper">
      <Select
        id={name}
        instanceId={name}
        className="type-dropdown"
        classNamePrefix="type-dropdown"
        options={QUESTION_TYPES}
        isDisabled={false}
        value={selected}
        isSearchable={false}
        onChange={option => onChange({ type: option.value })}
      />
    </div>
  );
};

export default QuestionTypeSelector;
