import { nanoid } from 'nanoid';

export const generateOptions = (opts = []) => {
  if (!opts.length) {
    return [];
  }
  const parsedOptions = opts.map(opt => {
    const isObject = typeof opt === 'object';
    if (isObject) {
      return {
        id: opt?.id || nanoid(3),
        value: opt?.value || '',
        label: opt?.label || opt?.value || ''
      };
    }
    return {
      id: nanoid(3),
      value: opt,
      label: opt
    };
  });
  return parsedOptions;
};
export const generateRows = ({ name, rows = [] }) => {
  // console.log({ name, rows });
  if (!rows.length || !name) {
    return [];
  }
  const parsedOptions = rows.map((opt, rowIndex) => {
    const fallbackCode = `${name}_${rowIndex + 1}`;
    const isObject = typeof opt === 'object';
    if (isObject) {
      return {
        id: opt?.id || nanoid(3),
        value: opt?.value || fallbackCode,
        label: opt?.label || opt?.value || fallbackCode
      };
    }
    return {
      id: nanoid(3),
      value: fallbackCode,
      label: opt
    };
  });
  return parsedOptions;
};

export const cleanObject = obj => {
  const cleaned = Object.keys(obj).reduce((acc, curr) => {
    const currValue = obj[curr];
    if (currValue) {
      return { ...acc, [curr]: currValue };
    }
    return acc;
  }, {});
  return cleaned;
};

export const getURL = () => {
  const url =
    process?.env?.NEXT_PUBLIC_WEB_URL && process.env.NEXT_PUBLIC_WEB_URL !== ''
      ? process.env.NEXT_PUBLIC_WEB_URL
      : process?.env?.VERCEL_URL && process.env.VERCEL_URL !== ''
      ? `https://${process.env.VERCEL_URL}`
      : 'http://localhost:3000';

  // return url.includes('http') ? url : https://${url}`;
  return url;
};

export const toDateTime = secs => {
  const t = new Date('1970-01-01T00:30:00Z'); // Unix epoch start.
  t.setSeconds(secs);
  return t;
};
