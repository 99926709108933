import { useState } from 'react';
import { CheckboxWrapper, QuestionSettingsDrawerStyles } from '@/styles/questions.styles';
import dynamic from 'next/dynamic';
import { Icon } from 'react-icons-kit';
import { copy } from 'react-icons-kit/fa/copy';
import { trashO } from 'react-icons-kit/fa/trashO';
import { settings } from 'react-icons-kit/feather/settings';
// import Tippy from '@/components/Tooltip';
// eslint-disable-next-line import/no-cycle
import { Checkbox } from '../Checkbox';

const Tippy = dynamic(() => import('@/components/Tooltip'), { ssr: false });

const QuestionSettingsDrawer = props => {
  const { question, name, validation, onChange, onDuplicate, onDelete, isPlainText } = props;
  // console.log({ props });
  // const [state, setState] = useState(validation.required || {});
  const [required, setRequired] = useState(validation?.required?.value || false);
  const [open, setOpen] = useState(false);
  const handleChange = val => {
    // console.log({ required: val });
    // const newValidation = { ...validation };
    setRequired(val);
    let newValidation;
    if (val) {
      newValidation = { ...validation, required: { value: true } };
    }
    if (!val) {
      newValidation = { ...validation, required: { value: false } };
    }
    // console.log({ newValidation });
    onChange(newValidation);
  };
  // console.log({ required });
  return (
    <>
      {open && <div>Config Is open!!</div>}
      <QuestionSettingsDrawerStyles isPlainText={isPlainText}>
        <div className="options-actions">
          {!isPlainText && (
            <Tippy content="Configs and Validation soon!">
              <div>
                <button
                  type="button"
                  aria-label="Question Config"
                  title="Question Config"
                  onClick={() => setOpen(!open)}
                  data-active={open}
                  disabled
                >
                  <Icon size={18} icon={settings} />
                </button>
              </div>
            </Tippy>
          )}
          <Tippy content="Duplicate">
            <button
              type="button"
              aria-label="duplicate question"
              title="Duplicate question"
              onClick={() => onDuplicate(question)}
            >
              <Icon size={18} icon={copy} />
            </button>
          </Tippy>

          <Tippy content="Delete">
            <button
              type="button"
              aria-label="delete question"
              title="Delete question"
              onClick={() => onDelete(question)}
            >
              <Icon size={18} icon={trashO} />
            </button>
          </Tippy>

          {!isPlainText && (
            <div className="required-opt">
              <CheckboxWrapper className="required-check-wrapper">
                <label title="required">
                  <Checkbox
                    id={`${name}-required`}
                    name={`${name}-required`}
                    value={required}
                    checked={required}
                    onChange={e => handleChange(e.target.checked)}
                  />
                  <div className="checkbox-label">Required</div>
                </label>
              </CheckboxWrapper>
            </div>
          )}
        </div>
      </QuestionSettingsDrawerStyles>
    </>
  );
};

export default QuestionSettingsDrawer;
