/* eslint-disable camelcase */
import { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { nanoid } from 'nanoid';
import dynamic from 'next/dynamic';
import { Icon } from 'react-icons-kit';
import { ic_add } from 'react-icons-kit/md/ic_add';
import { ic_remove } from 'react-icons-kit/md/ic_remove';
import { grabber } from 'react-icons-kit/oct/grabber';
import { helpCircle } from 'react-icons-kit/feather/helpCircle';
import { QuestionOptionsEditorStyles } from '@/styles/questions.styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import Tippy from '@/components/Tooltip';
const Tippy = dynamic(() => import('@/components/Tooltip'), { ssr: false });

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getQuestionDefaultValue = (arr, index) => {
  const range = Array.from({ length: 100 }, (_, i) => `${i + 1}`);
  const iterable = range.slice(index + 1); // start count on actual item index value
  // console.log({ iterable });

  for (let value of iterable) {
    if (!arr.includes(value)) {
      // console.log('not on arr', value);
      return { id: nanoid(3), value };
    }
    value += 1;
    // console.log(value);
  }
};

const QuestionOptionsEditor = ({ name, options = [], cols, onChange }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [state, setState] = useState(options?.length > 0 ? options : [{ id: 'dd', value: '' }]); // default to an empty option
  // console.log({ state });
  const addQuestionOnIndex = async index => {
    // console.log('adding question on index', index);
    // clone state arr
    const newState = [...state];
    const newItemValue = getQuestionDefaultValue(state, index);
    // console.log({ newItemValue });
    // mutate curr cloned state
    // slice(onArrIndex, itemsToRemove, itemsToAdd)
    newState.splice(index + 1, 0, newItemValue);
    await setState(newState);
    await onChange({ options: newState });
    return newState;
  };
  const removeQuestionOnIndex = index => {
    // console.log('removing question on index', index);
    // clone state arr
    const newState = [...state];
    // mutate curr cloned state
    // slice(onArrIndex, itemsToRemove, itemsToAdd)
    newState.splice(index, 1);
    setState(newState);
    onChange({ options: newState });
  };
  const handleInputChange = (value, index) => {
    // console.log({ value, index });
    const newState = [...state];
    // mutate new arr
    const newQuestionObject = { id: nanoid(3), value };
    newState[index] = newQuestionObject;
    // // filter out empty options
    // const filteredOptions = newOptions.filter(val => val);
    // // set new arr
    // // console.log({ filteredOptions });
    // const newFilteredOptions = filteredOptions.length > 0 ? filteredOptions : [''];
    // setState(newFilteredOptions);
    setState(newState);
    onChange({ options: newState });
  };

  const handleFocusIndex = (indexToFocus, updatedQuestionList) => {
    // console.log({ indexToFocus });
    let questionToFocus = state[indexToFocus];
    if (updatedQuestionList) {
      questionToFocus = updatedQuestionList[indexToFocus];
    }
    // console.log({ questionToFocus, state, options, updatedQuestionList });
    if (questionToFocus) {
      // console.log({ questionToFocus });
      const customTabFocusEvent = new CustomEvent('focus-question-id', {
        detail: {
          questionId: questionToFocus.id
        }
      });
      document.dispatchEvent(customTabFocusEvent);
    }
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(state, result.source.index, result.destination.index);

    // set new arr
    setState(newItems);
    onChange({ options: newItems });
  };

  // console.log({ state });
  const COL_OPTIONS = state.map((opt, index) => ({
    value: index + 1,
    label: index + 1
  }));
  const selectedCol = COL_OPTIONS.find(col => col.value === cols);

  return (
    <QuestionOptionsEditorStyles>
      <div className="options-header">
        <div className="options-label">
          <div>Options</div>
          {cols && (
            <>
              <div className="cols-label">
                Cols{' '}
                <span className="help">
                  <Tippy
                    content={
                      <div className="help-tooltip">
                        <div>Number of colums to display your options layout</div>
                      </div>
                    }
                    visible={showTooltip}
                    onClickOutside={() => setShowTooltip(false)}
                  >
                    <button type="button" onClick={() => setShowTooltip(!showTooltip)}>
                      <Icon size={12} icon={helpCircle} />
                    </button>
                  </Tippy>
                </span>
              </div>

              <div className="cols-number-wrapper">
                <Select
                  id={`${name}_cols`}
                  instanceId={`${name}_cols`}
                  className="cols-dropdown"
                  classNamePrefix="cols-dropdown"
                  options={COL_OPTIONS}
                  isDisabled={state.length === 0}
                  value={selectedCol || { value: 1, label: 1 }}
                  isSearchable={false}
                  onChange={option => onChange({ cols: option.value })}
                />
              </div>
            </>
          )}
        </div>
        <div className="options-desc">Add and remove question options below:</div>
      </div>

      {state.length > 0 && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`droppable_${name}`} className="option-list-wrapper">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                // style={getListStyle(snapshot.isDraggingOver)}
                className={`option-list-wrapper${snapshot.isDraggingOver ? ' dragging' : ''}`}
              >
                {state.map((opt, index) => (
                  <DraggableOptionItem
                    // key={`${opt?.value || opt}_${index}`}
                    // id={`${opt?.value || opt}_${index}`}
                    key={opt.id}
                    id={opt.id}
                    index={index}
                    option={opt}
                    optionsLength={state.length}
                    handleInputChange={handleInputChange}
                    addQuestionOnIndex={addQuestionOnIndex}
                    removeQuestionOnIndex={removeQuestionOnIndex}
                    handleFocusIndex={(...args) => handleFocusIndex(...args)}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </QuestionOptionsEditorStyles>
  );
};

const ContainedInput = ({
  value,
  option,
  index,
  optionsLength,
  onChange,
  onEnterKey,
  onBackSpaceKey,
  onFocusIndex,
  isFirst
}) => {
  const [inputValue, setInputValue] = useState(value);
  const containedRef = useRef();
  // // console.log({ containedRef });
  // const handleKeyUp = async e => {
  //   const { keyCode, key } = e;
  //   // const isBackSpace = key === 'Backspace' || keyCode === 8;
  //   // const isEnter = key === 'Enter' || keyCode === 13;
  //   // console.log({ keyCode, key });
  //   // console.log({ isEnter, isBackSpace });
  //   // if (isBackSpace) {
  //   //   if (isFirst) {
  //   //     // if first item, check if has more items
  //   //     const hasMoreOptions = optionsLength > 1;
  //   //     if (!hasMoreOptions) {
  //   //       // if is first item AND no more options, do not delete
  //   //       return;
  //   //     }
  //   //   }
  //   //   // if backSpace and no value (meaning input empty)
  //   //   if (!inputValue) {
  //   //     const prevIndex = index - 1;
  //   //     // if isFirst, set index to 1, as index 1 (next to be 0) is on ref and can focus
  //   //     const indexToFocus = isFirst ? 1 : prevIndex;
  //   //     if (indexToFocus >= 0) {
  //   //       await onFocusIndex(indexToFocus);
  //   //     }
  //   //     onBackSpaceKey();
  //   //   }
  //   // }
  // };

  const handleKeyDown = async e => {
    const { keyCode, key } = e;
    const isBackSpace = key === 'Backspace' || keyCode === 8;
    const isEnter = key === 'Enter' || keyCode === 13;
    // console.log('Key Down');
    // console.log({ isBackSpace, isEnter });
    if (isEnter) {
      e.preventDefault();
      const newUpdatedOptions = await onEnterKey();
      await onFocusIndex(index + 1, newUpdatedOptions);
    }
    if (isBackSpace) {
      if (isFirst) {
        // if first item, check if has more items
        const hasMoreOptions = optionsLength > 1;
        if (!hasMoreOptions) {
          // if is first item AND no more options, do not delete
          return;
        }
      }
      // if backSpace and no value (meaning input empty)
      if (!inputValue) {
        e.preventDefault();
        const prevIndex = index - 1;
        // if isFirst, set index to 1, as index 1 (next to be 0) is on ref and can focus
        const indexToFocus = isFirst ? 1 : prevIndex;
        if (indexToFocus >= 0) {
          await onFocusIndex(indexToFocus);
        }
        onBackSpaceKey();
      }
    }
    // e.preventDefault();
    // console.log({ keyCode, key });
    const isTab = key === 'Tab' || keyCode === 9;
    if (isTab) {
      // console.log('is tab');
      // console.log({ currentIndex: index + 1, optionsLength });
      if (index + 1 < optionsLength) {
        e.preventDefault();
        // console.log('focus next item');
        onFocusIndex(index + 1);
      }
    }
  };

  useEffect(() => {
    const handleDeselectEvent = e => {
      // e.preventDefault();
      // console.log(e);
      // console.log('deselect');
      // // save state
      // console.log({ inputValue });
      // console.log({ value, inputValue });
      if (value !== inputValue) {
        onChange(inputValue);
      }
    };

    // add when mounted
    document.addEventListener('deselect-question', handleDeselectEvent);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('deselect-question', handleDeselectEvent);
    };
  }, [inputValue]);

  useEffect(() => {
    const handleCustomFocusEvent = e => {
      // e.preventDefault();
      // console.log('handleCustomFocusEvent');
      const { questionId } = e.detail;
      if (questionId === option.id) {
        // console.log('should focus current!', questionId);
        if (containedRef.current) {
          containedRef.current.focus();
        }
      }
    };
    // add when mounted
    document.addEventListener('focus-question-id', handleCustomFocusEvent);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('focus-question-id', handleCustomFocusEvent);
    };
  }, []);

  const handleBlurChange = () => {
    if (value !== inputValue) {
      onChange(inputValue);
    }
  };

  return (
    <input
      ref={containedRef}
      value={inputValue}
      onChange={e => setInputValue(e.target.value)}
      // onChange={console.log}
      // onChange={e => {
      //   setInputValue(e.target.value);
      //   // onChange(e.target.value);
      // }}
      onBlur={handleBlurChange}
      // onFocus={console.log('focus', option.id)}
      // onMouseEnter={() => onChange(inputValue)}
      // onMouseLeave={() => onChange(inputValue)}
      // onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
    />
  );
};

const DraggableOptionItem = ({
  id,
  index,
  option,
  optionsLength,
  handleInputChange,
  addQuestionOnIndex,
  removeQuestionOnIndex,
  handleFocusIndex
}) => (
  // const [inputValue, setInputValue] = useState(option?.value || '');
  // const handleChange = async value => {
  //   await setInputValue(value);
  //   await handleInputChange(value, index);
  // };
  <Draggable key={id} draggableId={id} index={index}>
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        style={{ ...provided.draggableProps.style }}
        className={`option-row${snapshot.isDragging ? ' dragging' : ''}`}
      >
        <div {...provided.dragHandleProps} className="opt-grabber">
          <Icon icon={grabber} />
        </div>

        <div className="opt-input">
          <ContainedInput
            // value={inputValue}
            // onChange={value => handleChange(value)}
            key={option.id}
            option={option}
            index={index}
            optionsLength={optionsLength}
            value={option?.value || ''}
            onChange={value => handleInputChange(value, index)}
            onEnterKey={async () => addQuestionOnIndex(index)}
            onBackSpaceKey={() => removeQuestionOnIndex(index)}
            isFirst={index === 0}
            onFocusIndex={handleFocusIndex}
          />
        </div>
        <div className="opt-actions">
          <button
            type="button"
            aria-label="Question Config"
            title="Question Config"
            onClick={() => addQuestionOnIndex(index)}
          >
            <Icon icon={ic_add} />
          </button>

          <button
            type="button"
            aria-label="Question Config"
            title="Question Config"
            onClick={() => removeQuestionOnIndex(index)}
            disabled={index === 0 && optionsLength <= 1}
          >
            <Icon icon={ic_remove} />
          </button>
        </div>
      </div>
    )}
  </Draggable>
);
export default QuestionOptionsEditor;
