import { useState, useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { CustomCheckboxContainer, CustomCheckboxInput } from '@reach/checkbox';
import '@reach/checkbox/styles.css';

import { CheckboxWrapper } from '@/styles/questions.styles';
import { generateOptions } from '@/utils/index';
import QuestionTypeSelector from './Shared/QuestionTypeSelector';
import QuestionSettingsDrawer from './Shared/QuestionSettingsDrawer';
// import QuestionEditorHeader from './Shared/QuestionEditorHeader';
import QuestionOptionsEditor from './Shared/QuestionOptionsEditor';
import QuestionError from './Shared/QuestionError';

const CheckboxComponent = props => {
  const {
    type,
    name,
    label,
    title,
    control,
    options,
    rules = {},
    helper,
    error,
    value,
    cols = 1,
    checkAll,
    isVisible = true,
    editable,
    onChange,
    onDuplicate,
    onDelete
  } = props;

  // handle automatic title input focus on selected question
  const titleRef = useRef();
  useEffect(() => {
    if (editable) {
      if (titleRef.current) {
        titleRef.current.focus();
      }
    }
  }, [editable]);

  if (!isVisible) {
    return null;
  }

  return (
    <CheckboxWrapper className="checkbox-question" cols={cols} errorState={error}>
      {/* {editable && <QuestionEditorHeader name={name} onChange={val => onChange({ name: val })} />} */}

      {editable ? (
        <div className="question-info-select">
          <input
            ref={titleRef}
            className="qst-title-input"
            placeholder="Write here your question title..."
            defaultValue={label}
            onChange={e => onChange({ title: e.target.value })}
          />{' '}
          <QuestionTypeSelector
            name={name}
            type={type}
            onChange={val => onChange({ type: val.type })}
          />
        </div>
      ) : (
        <div
          id={name}
          className={`qst-title${label ? '' : ' placeholder'}${title ? '' : ' empty'}`}
        >
          {label || 'Write here your question title...'}
          {rules?.required?.value === true && (
            <span title="Required" aria-label="Required" className="required-mark">
              *
            </span>
          )}
        </div>
      )}

      {editable && (
        <input
          className="qst-helper-input"
          placeholder="Question footer, note or helper text..."
          defaultValue={typeof helper === 'function' ? helper() : helper}
          onChange={e => onChange({ helper: e.target.value })}
        />
      )}

      {editable && (
        <CheckAllSelect
          name={name}
          checkAll={checkAll}
          onChange={val => onChange({ checkAll: val })}
        />
      )}
      {editable && (
        <QuestionOptionsEditor
          name={name}
          options={options}
          cols={cols}
          onChange={updatedVals => onChange(updatedVals)}
        />
      )}

      {!editable && (
        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={value || null}
          render={({ field }) => (
            <CheckboxController
              {...props}
              itemRef={field.ref}
              onChange={val => field.onChange(val)}
            />
          )} // props contains: onChange, onBlur and value
        />
      )}

      {!editable && helper && (
        <span className="form-text question-helper">
          {typeof helper === 'function' ? helper() : helper}
        </span>
      )}
      <QuestionError error={error} />

      {/**
            {editable && (
        <div>
          <pre>
            {JSON.stringify(
              { name, type, label, options, cols, checkAll, rules, helper },
              (key, keyVal) => (typeof keyVal === 'undefined' ? null : keyVal),
              2
            )}
          </pre>
        </div>
      )}
       */}

      {editable && (
        <QuestionSettingsDrawer
          question={props}
          name={name}
          type={type}
          validation={rules}
          onChange={validation => onChange({ validation })}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
        />
      )}
    </CheckboxWrapper>
  );
};

const CheckAllSelect = ({ name, checkAll, onChange }) => (
  <div>
    <div className="options-header">
      <div className="options-label">
        <div>
          <em>Select All</em> Option
        </div>
        <div className="cols-number-wrapper">
          <div className="selectAll-opt">
            <CheckboxWrapper className="selectAll-check-wrapper">
              <label>
                <Checkbox
                  id={`${name}-checkAll`}
                  name={`${name}-checkAll`}
                  value="checkAll"
                  checked={checkAll}
                  onChange={() => onChange(!checkAll)}
                />
                <div className="checkbox-label">Display Select All</div>
              </label>
            </CheckboxWrapper>
          </div>
        </div>
      </div>
      <div className="options-desc">
        Add a "Select All" option to select or unselect all options at once
      </div>
    </div>
  </div>
);

export const Checkbox = props => {
  const { inputRef, ...restOfProps } = props;
  const { id, checked, disabled } = props;
  const isMixed = checked === 'mixed';
  const [checkedState, setChecked] = useState(() => (isMixed ? 'mixed' : checked === true));
  const derivedChecked = isMixed ? 'mixed' : checked || checkedState;
  // console.log({ id, checked, checkedState, derivedChecked });
  const externalRef = inputRef ? { ref: inputRef } : {};

  return (
    <CustomCheckboxContainer
      onChange={event => setChecked(event.target.checked)}
      aria-disabled={disabled}
      checked={derivedChecked}
      {...restOfProps}
    >
      <CustomCheckboxInput
        id={id}
        {...externalRef}
        disabled={disabled}
        aria-disabled={disabled}
        {...restOfProps}
      />
      {checked && <span aria-hidden aria-checked={derivedChecked} aria-disabled={disabled} />}
    </CustomCheckboxContainer>
  );
};

const CheckboxController = props => {
  const {
    name,
    options = [],
    value,
    error,
    onChange,
    checkAll,
    itemRef,
    disabled,
    ...restProps
  } = props;
  // console.log({ restProps });
  const parsedOptions = generateOptions(options);

  const optionsDetails = parsedOptions.map(opt => opt && { ...opt, checked: false });
  const [selected, setSelected] = useState(value || optionsDetails); // for controlled component if value

  // We can determine if all or some of the nested checkboxes are selected and
  // use that to determine the state of our parent checkbox.
  const allItemsChecked = selected.every(item => item.checked === true);

  const someItemsChecked = allItemsChecked ? false : selected.some(items => items.checked === true);

  const parentIsChecked = allItemsChecked ? true : someItemsChecked ? 'mixed' : false;

  const handleOnchange = async newSelected => {
    // console.log({ newSelected });
    const isNoneSelected = newSelected.every(box => box.checked === false);
    // console.log({ isNoneSelected });
    if (isNoneSelected) {
      await onChange(null); // set value null
    } else {
      await onChange(newSelected);
    }
  };
  // When we toggle a parent checkbox, we expect all of the nested checkboxes
  // to toggle with it.
  const handleParentChange = async e => {
    const newSelected = selected.map(item => ({
      ...item,
      checked: !allItemsChecked
    }));
    await setSelected(newSelected);
    await handleOnchange(newSelected);
  };

  const handleChildChange = async event => {
    // console.log(event);
    const { target } = event;
    // console.log({ checked, value });
    const newState = [...selected];
    const clickedOption = newState.find(item => `${item.value}` === target.value);
    const clickedIndex = newState.indexOf(clickedOption);
    const newOptionState = { ...clickedOption, checked: target.checked };
    // console.log({ newState, clickedOption, clickedIndex, newOptionState });
    newState[clickedIndex] = newOptionState;
    await setSelected(newState);
    await handleOnchange(newState);
  };

  return (
    <>
      {checkAll && (
        <label aria-disabled={disabled} className="checkbox-checkall">
          <Checkbox
            value="all-selector"
            checked={parentIsChecked}
            onChange={handleParentChange}
            disabled={disabled}
            className="pollme-checkbox"
          />
          <div className="checkbox-label">{allItemsChecked ? 'Unselect' : 'Select'} all</div>
        </label>
      )}
      <ul aria-labelledby={name}>
        {selected.map((item, i) => {
          const focusProps = i === 0 ? { ref: itemRef } : {};
          return (
            <li
              // key={item.value}
              key={item.id}
            >
              <label
                className="checkbox-option"
                aria-disabled={disabled}
                data-invalid={error ? 'true' : 'false'}
                data-checked={item.checked}
                aria-checked={item.checked}
                {...focusProps}
              >
                <Checkbox
                  name={name}
                  value={`${item.value}`}
                  checked={item.checked}
                  onChange={handleChildChange}
                  disabled={disabled}
                  className="pollme-checkbox"
                />
                <div className="checkbox-label">{item.label || item.value}</div>
              </label>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default CheckboxComponent;
// https://reach.tech/checkbox
