import { Icon } from 'react-icons-kit';
import { alertTriangle } from 'react-icons-kit/feather/alertTriangle';
import styled from 'styled-components';

const ErrorSpan = styled.span`
  display: grid !important;
  grid-template-columns: 14px 1fr;
  grid-gap: 0.15rem;
  align-items: center;
  font-size: smaller;
  margin-top: 0.25rem;
  color: var(--danger);
  i {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
`;

const QuestionError = ({ error }) => {
  if (!error) {
    return null;
  }
  return (
    <ErrorSpan role="alert">
      <Icon icon={alertTriangle} size={12} />
      <span>{error.message}</span>
    </ErrorSpan>
  );
};

export default QuestionError;
